<template>
  <div class="d-flex justify-content-center align-items-center bg-white my-5" v-if="isLoading">
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>

  <b-card v-else no-body class="rounded-0">
    <b-card-header class="p-3 bg-light d-flex justify-content-between">
      <b-input-group  style="width: 400px;">
        <template #prepend>
          <b-input-group-text class="bg-white pr-0">
            <fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon>
          </b-input-group-text>
        </template>
        <template #append>
          <b-button variant="dark" v-on:click="clearFilter()"><fa-icon :icon="['fa-regular', 'fa-xmark']"></fa-icon></b-button>
        </template>
        <b-input placeholder="Suche" class="border-left-0" v-model="filter" debounce="600"></b-input>
      </b-input-group>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        class="mb-0"
      ></b-pagination>
    </b-card-header>
    <b-table
      :sticky-header="tableHeight"
      :items="customers"
      :fields="fields"
      class="mb-0"
      :filter="$store.state.customerFilter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      ref="table"
      :per-page="perPage"
      :current-page="currentPage"
      :no-provider-paging="true"
      :no-provider-sorting="true"
      :no-provider-filtering="true"
      @filtered="onFiltered"
      >
      <template #cell(companyName)="data">
        {{ data.item.companyName}}
      </template>
      <template #cell(resellerId)="data">
        <router-link :to="{ name: 'ResellersView', query: { q: data.item.resellerId } }">{{ data.item.resellerId }}</router-link><br/>
        <router-link :to="{ name: 'ResellersView', query: { q: data.item.resellerId } }"><small>{{names?.[data.item.resellerId]}}</small></router-link>
      </template>
      <template #cell(customerId)="data">
        {{ data.item.customerId }}
      </template>
      <template #cell(cotermDate)="data">
        {{ data.item.cotermDate | date }}
      </template>
      <template #cell(marketSegment)="data">
        {{ data.item.marketSegment }}
      </template>
      <template #cell(3yc)="data">
        <span v-if="data.item.allData.benefits?.filter(d => d.type === 'THREE_YEAR_COMMIT').length > 0 && data.item.allData.benefits?.filter(d => d.type === `THREE_YEAR_COMMIT`)?.[0]?.commitment?.startDate">
          <small>{{ data.item.allData.benefits?.filter(d => d.type === "THREE_YEAR_COMMIT")[0].commitment.startDate.toDate() | dateShort  }} -<br> {{ data.item.allData.benefits?.filter(d => d.type === "THREE_YEAR_COMMIT")[0].commitment.endDate.toDate() | dateShort }}</small>
        </span>
      </template>
      <template #cell(status)="data">
        <fa-icon
          :icon="['fa-regular', statusIcon(data.item.status )]"
          :class="`mr-1 ${statusClass(data.item.status )}`"
        ></fa-icon>
        {{ data.item.status }}
        {{ data.item.status | accountStatus }}
      </template>
      <template #cell(creationDate)="data">
        {{ data.item.creationDate | dateTime }}
      </template>
      <template #cell(hiddenProducts)="data">
        <template v-if="data.item.allData?.showHiddenProducts">
        <span v-for="(hiddenProduct, index) in data.item.allData.showHiddenProducts" :key="index"><small>{{ hiddenProduct }}</small></span>
        </template>
      </template>
      <template #cell(actions)="data">
        <div class="text-right">
          <b-dropdown id="dropdown-dropleft" toggle-class="text-decoration-none" dropleft variant="link" no-caret>
            <template #button-content>
              <fa-icon :icon="['fa-regular', 'ellipsis-vertical']" size="xl" color="darkgrey"></fa-icon>
            </template>
            <ShowHiddenProducts :customer="data.item.allData" :docId="data.item.docId"/>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <template #footer>
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column align-items-center text-muted">
          {{ rows }} Kunden
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
import { firestore } from "../plugins/firebase"
import { collection, getDocs } from "firebase/firestore"; 
import ShowHiddenProducts from '../components/ShowHiddenProducts.vue';

export default {
  components: { ShowHiddenProducts },
  name: "CustomersView",
  data() {
    return {
      rows: 0,
      perPage: 25,
      currentPage: 1,
      filter: "",
      isLoading: true,
      customers: [],
      sortBy: "creationDate",
      sortDesc: true,
      names: {},
      fields: [
        {
          key: "companyName",
          label: "Customer",
          sortable: true,
        },
        {
          key: "customerId",
          label: "Customer-ID",
          sortable: true,
        },
        {
          key: "resellerId",
          label: "Reseller-ID",
          sortable: true,
        },
        {
          key: "cotermDate",
          label: "Anniversary-Date",
          sortable: true,
        },
        {
          key: "marketSegment",
          label: "Marktsegment",
          sortable: true,
        },
        {
          key: "3yc",
          label: "3-Year-Commit",
          sortable: false,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "creationDate",
          label: "Creation-Date",
          sortable: true,
        },
        {
          key: "hiddenProducts",
          label: "Fallback",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getCustomers();
  },
  computed: {
    tableHeight() {
      return window.innerHeight - 64 - 71 - 51 + "px";
    },
  },
  watch: {
    filter(filter) {
      this.$store.commit("customerFilter", filter);
    },
  },
  methods: {
    async getCustomers() {
      this.getResellerName()
      try {
        const querySnapshot = await getDocs(collection(firestore, "customers"));
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.customers.push({
            "customerId": data.customerId,
            "companyName": data.companyProfile.companyName,
            "creationDate": data.creationDate,
            "cotermDate": data.cotermDate,
            "resellerId": data.resellerId,
            "status": data.status,
            "marketSegment": data.companyProfile.marketSegment,
            "allData": data,
            "docId": doc.id
          });
        })
        this.rows = this.customers.length;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    async getResellerName() {
      getDocs(collection(firestore, "resellers"))
      .then(snap=>{
        snap.forEach((doc) => {
          const data = doc.data();
          this.names[data.resellerId] = data.companyProfile.companyName 
        })
      })
    },
statusClass(status) {
      if (status === "1000") {
        return "text-success";
      } else if (status === "1002") {
        return "text-warning";
      } else if (status === "404") {
        return "text-muted";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === "1000") {
        return "fa-circle-check";
      } else if (status === "1002") {
        return "fa-circle";
      } else {
        return "fa-circle-xmark";
      }
    },
    clearFilter() {
      this.filter = "";
    },
    onFiltered(filteredItems) {
      console.log(filteredItems.length);
      this.rows = filteredItems.length
    },
  },
};
</script>
